import React from 'react'
import ChatGPT from '../../templates/chatgpt'

const ChatGPTEnPage = () => {
    return (
        <ChatGPT lang="en">
        </ChatGPT>
    )
}

export default ChatGPTEnPage
